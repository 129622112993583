@tailwind base;

@layer base {
  body.noscroll {
    @apply fixed overflow-y-scroll w-full;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s ease,
                backdrop-filter .5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  [v-cloak] {
    @apply hidden;
  }

  article a {
    @apply transition text-brand font-medium;

    &:hover {
      @apply text-white;
    }
  }
}

@tailwind components;

@tailwind utilities;
